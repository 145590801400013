<template>
  <v-container class="mx-auto" v-if="$store.getters.reservationSuccess">
    <div class="margin-bottom-24 mt-3">
      <v-col class="p-0" style="top: 0" id="printableArea">
        <v-card outlined class="margin-bottom-24">
          <div class="d-flex flex-column align-center py-16">
            <img :src="ReservationSuccess" class="mv-4 mb-4" />
            <h4 class="text-h4 reserve-success-text">
              {{ $t("Reservation.ReservationSuccessfully") }}
            </h4>
            <h5 class="text-h5 reserve-success-model">
              <b>
                {{ listing.Cardsdata.Make }} {{ listing.Cardsdata.Model }}
                {{ listing.Cardsdata.Year }}
              </b>
            </h5>
            <h5 class="text-h5 reserve-success-number">
              {{ $t("Reservation.YourReservationNumber") }}:
              <div>{{ $store.getters.reservationNumber ? $store.getters.reservationNumber.ReservationRef : '' }}</div>
            </h5>
          </div>
        </v-card>
        <div
            class="margin-bottom-24 success-alert custom-alert"
            v-if="reservationGet.payResult"
        >
          <span>{{ $t("Reservation.ReservationSuccessfullyMessage") }}</span>
        </div>
        <v-alert
            class="margin-bottom-24 danger-alert custom-alert"
            v-if="!reservationGet.payResult"
        >
          <div v-if="reservationGet.payResult === false">
            {{ $t("Reservation.ReservationFail") }}
          </div>
          <span
              v-html="
              $t('Reservation.ReservationFailMessage', {
                fees: currencyFormatter(deposit),
              })
            "
          />
        </v-alert>
        <v-card
            outlined
            class="py-4 mx-auto margin-bottom-24 padding-24 agent-card"
        >
          <div class="">
            <v-card-title class="card-title">{{
                $t("Reservation.MyRepresentative")
              }}</v-card-title>
            <v-divider class="mt-0 mx-0" />
            <TheSalesAgents :agents="$store.getters.reservationAgent" />
          </div>
        </v-card>
        <v-card outlined class="margin-bottom-24 important-info">
          <div class="">
            <h4 class="font-weight-bold">
              {{ $t("Reservation.ImportantInformation") }}
            </h4>
            <ul style="line-height: 2rem">
              <li>
                {{ $t("Reservation.TheReservationDepositIsFullyReimbursable") }}
              </li>
              <li>
                {{
                  $t("Reservation.ForAnyQuestionsOrHelpRegardingYourPurchase")
                }}
              </li>
              <li>
                {{ $t("Reservation.SubmitAComplaint") }} <address>
                <a :href="`mailto:${$t('Reservation.SubmitAComplaintEmail')}`">{{ $t("Reservation.SubmitAComplaintEmail") }}</a>
              </address>
              </li>
            </ul>
          </div>
        </v-card>
        <div class="">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  text
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  class="text-link"
              >
                <img :src="SharingIcon" class="mr-3" />{{
                  $t("Reservation.ShareReservation")
                }}
              </v-btn>
            </template>

            <v-list>
              <v-list-item v-for="network in networks" :key="network.network">
                <ShareNetwork
                    :network="network.network"
                    :url="sharing.url"
                    :title="sharing.title"
                    :description="sharing.description"
                    :quote="sharing.quote"
                    :hashtags="sharing.hashtags"
                    :twitterUser="sharing.twitterUser"
                >
                  <v-list-item-title class="d-flex align-center">
                    <v-icon :color="network.color"
                    >mdi-{{ network.icon }}</v-icon
                    >
                    {{ network.name }}
                  </v-list-item-title>
                </ShareNetwork>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
              @click="printWindow('printableArea')"
              text
              color="primary"
              class="text-link"
          >
            <img :src="PrintIcon" class="mr-3" /> {{ $t("Reservation.Print") }}
          </v-btn>
        </div>
        <v-divider />
        <div class="d-flex justify-content-end align-center action-buttons">
          <v-btn
              rounded
              outlined
              color="primary"
              :to="{ name: 'BuyerDashboard' }"
          >
            {{ $t("Reservation.GoToDashboard") }}
          </v-btn>
          <v-btn
              rounded
              color="primary"
              :to="{ name: 'SearchResults' }"
              class="find-btn"
          >
            {{ $t("Reservation.FindAnotherCar") }}
          </v-btn>
        </div>
      </v-col>
    </div>
  </v-container>
  <v-container class="mx-auto mt-3" v-else>
    <v-row align="start">
      <v-col class="mv-4">
        <v-card outlined class="reservation-header">
          <h3 class="text-h4 text-center mv-0 font-weight-bold mb-0">
            {{ $t("Reservation.YourVehicleReservation") }}
          </h3>
        </v-card>
      </v-col>
    </v-row>
    <v-col class="mt-md-6 mt-4">
      <v-row align="start" justify="space-between" class="mv-16">
        <v-col class="p-0 mr-6 deliveryRoute">
          <!-- Car details -->
          <TheCarCard
            :carDetail="listing.Cardsdata"
            :price="priceData"
            class="rounded"
            v-if="listing && listing.Cardsdata"
          />
          <!-- Country -->
          <v-card outlined class="delivery-route-card">
            <v-card-title>{{ $t("Reservation.DeliveryRoute") }}</v-card-title>
            <div class="d-flex justify-content-center flex-column">
              <div class="country-wrapper">
                <div class="country text-center">
                  <div v-if="listing">
                    <country-flag
                      :country="listing.Delivery.SourceCountry.Code"
                      rounded
                      style="vertical-align: middle"
                    />
                    <p>
                      <b>{{ listing.Delivery.SourceCountry.Name }}</b>
                    </p>
                  </div>
                </div>

                <v-col class="m-auto p-0 text-center">
                  <v-icon large color="primary">mdi-arrow-right-thin</v-icon>
                </v-col>

                <div class="country text-center">
                  <div v-if="listing">
                    <country-flag
                      :country="listing.Delivery.DestinationCountry.Code"
                      rounded
                      style="vertical-align: middle"
                    />
                    <p>
                      <b>{{ listing.Delivery.DestinationCountry.Name }}</b>
                    </p>
                  </div>
                </div>
              </div>
              <v-col class="text-center py-0 pb-0" cols="12">
                <h6 class="font-600">{{ $t("Reservation.ShouldArriveBy") }}</h6>
                <h6 class="mv-0">{{ getDeliveryDate }}</h6>
              </v-col>
            </div>
          </v-card>
          <!-- Guarantee -->
          <v-card elevation="0" class="bg-primary deliveryRoute py-2">
            <v-container>
              <v-row no-gutters align="center" justify="center">
                <v-col cols="auto" style="max-width: 100px" class="mr-4">
                  <img
                    :src="GuaranteeImage"
                    alt="Guarantee Image"
                    style="max-width: 100px"
                  />
                </v-col>
                <v-col cols="6" class="text-white guarantee-text">
                  <h5>
                    {{ $t("Reservation.DeliveryGuarantee") }}
                  </h5>
                  <a href="#" v-b-modal.gridModal style="color: white !important">{{
                    $t("Reservation.LearnMore")
                  }}</a>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
        <b-modal hide-footer id="gridModal" :title='$t("Reservation.DeliveryGuarantee")'>
          <div class="p-4">
            <div>{{ $t("global.label.comingsoon") }}</div>
          </div>
        </b-modal>
        <v-col
          class="mx-0 px-0 pt-0 flex-1 w-100"
          v-show="!$store.getters.reservationSuccess"
        >
          <vue-good-wizard
            ref="reservationWizard"
            :steps="steps"
            :onNext="nextClicked"
            :onBack="backClicked"
            :nextStepLabel="$t('Reservation.Continue')"
            :previousStepLabel="$t('listing.label.back')"
            class="ReservationWizard"
          >
            <div slot="page1">
              <TheReservationContact :nextButton="nextButtonDisabled" />
            </div>
            <div slot="page2">
              <TheReservationSummary
                :nextButton="nextButtonDisabled"
                @submitReservation="createNewReserve"
              />
            </div>
          </vue-good-wizard>
        </v-col> </v-row
    ></v-col>
  </v-container>
</template>

<script>
import axios from "axios";
import TheReservationContact from "@/components/reservation/TheReservationContact";
import TheReservationSummary from "@/components/reservation/TheReservationSummary";
import GuaranteeImage from "@/assets/img/reservation-guaranteeImage.svg";
import ReservationSuccess from "@/assets/img/reservation-success.svg";
import PrintIcon from "@/assets/img/icons/print.svg";
import SharingIcon from "@/assets/img/icons/sharing.svg";
import _find from "lodash/find";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  components: {
    TheReservationSummary,
    TheReservationContact,
    TheSalesAgents: () => import("@/components/reservation/TheSalesAgentCard"),
    TheCarCard: () => import("@/components/global/TheCarCardReservation.vue"),
  },
  name: "Reservation",
  data() {
    return {
      steps: [
        {
          label: "Contact",
          slot: "page1",
          options: {
            nextDisabled: true,
          },
        },
        {
          label: "Summary",
          slot: "page2",
          options: {
            nextDisabled: true,
          },
        },
      ],
      exportPort: null,
      entryPort: null,
      arriveTime: null,
      paymentLink: null,
      reservationCode: null,
      fees: null,
      deposit: null,
      sharing: {
        url: `https://pashmotor.com/reservation`,
        title: `Reservation Receipt `,
        description: "Please check your reservation details in here",
        quote: "",
      },
      networks: [
        {
          network: "email",
          name: "Email",
          icon: "email",
          color: "red",
        },
        {
          network: "whatsapp",
          name: "Whatsapp",
          icon: "whatsapp",
          color: "green",
        },
      ],
      GuaranteeImage,
      ReservationSuccess,
      PrintIcon,
      SharingIcon,
    };
  },
  computed: {
    ...mapGetters(["user", "reservationGet"]),
    destinationCountry() {
      return this.$store.getters["destinationCountry"];
    },
    listing() {
      if (this.$store.getters.listing) {
        return this.$store.getters.listing;
      } else {
        return null;
      }
    },
    getDeliveryDate() {
      if (this.listing) {
        return moment(this.listing.Delivery.DeliveryDate).format(
          "ddd DD MMM YYYY"
        );
      }
      return null;
    },
    priceData() {
      let price = {};
      if (this.listing) {
        if (this.listing.Fees)
          this.listing.Fees.forEach((element) => {
            if (element.Name === "Total") {
              price = element.Price;
            }
          });
      }
      // return this.currencyFormatter(price);
      return price;
    },
    carData() {
      //Parses the lsiting data
      if (this.listing) {
        return JSON.parse(this.listing.TagCategories);
      } else {
        return null;
      }
    },
    carDetail() {
      //Creates an array of the different tags and values
      if (this.carData) {
        let details = this.carData.flatMap((el) => el.Tags);
        return details;
      } else return null;
    },
    searchCountry() {
      if (this.$store.getters.destinationCountry) {
        return this.$store.getters.destinationCountry;
      } else return null;
    },
    vehicleCountry() {
      /* cd_CountryCode: // cd_CountryName: // cd_LanguageCode: */
      if (this.$store.getters.listing.Country) {
        return JSON.parse(this.$store.getters.listing.Country);
      } else return null;
    },
    vehicleCountryName() {
      if (this.vehicleCountry) return this.vehicleCountry.CountryName;
      else return "";
    },
    vehicleCountryCode() {
      if (this.vehicleCountry) return this.vehicleCountry.CountryCode;
      else return "";
    },
    interiorColor() {
      return "null";
    },
    exteriorColor() {
      return "null";
    },
  },
  methods: {
    nextClicked(currentPage) {
      if (!this.$store.getters.user || !localStorage.getItem("token")) {
        this.$bvModal.show("signin");
        return false;
      }
      if (currentPage === 1) {
        this.createNewReserve();
        window.scrollTo(0,0)
        return true;
      }
      if (this.steps[currentPage].options.nextDisabled) return false;
      //console.log("next clicked", currentPage);
      window.scrollTo(0,0)
      return true; //return false if you want to prevent moving to next page
    },
    backClicked() {
      window.scrollTo(0,0)
      return true; //return false if you want to prevent moving to previous page
    },
    printWindow(divName) {
      var printContents = document.getElementById(divName).innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    },
    nextButtonDisabled(page, boolean) {
      this.steps.forEach((element) => {
        if (element.slot === page) {
          element.options.nextDisabled = boolean;
        }
      });
    },
    async creditPayment(payment, response) {
      try {
        await axios
          .post("Reservation/Payment", {
            ReservationId: response.ReservationId,
            CardNo: payment.cardNumber.replace(/\s/g, ""),
            HolderName: payment.name,
            ExpireYear: "20" + payment.expiration.split("/")[1],
            ExpireMonth: payment.expiration.split("/")[0],
            CVV: payment.security,
          })
          .then(() => {
            this.$store.dispatch("reservationSuccess", true);
            this.$store.dispatch("reservationPayResult", true);
            this.$store.dispatch("reservationNumber", response.data);
            this.$store.dispatch("reservation/reservationKeepData", false);
          })
          .catch((error) => {
            this.$store.dispatch("reservationPayResult", false);
            this.$store.dispatch("reservationSuccess", true);
            console.log(error);
          });
      } catch (error) {
        this.errorMessage = error;
        if (process.env.NODE_ENV === "development") console.log(error.data);
        throw error
      }
    },
    async createNewReserve(payment) {
      try {
        const state = this.$store.getters.reservationGet;
        const Contact = {
          FirstName: this.$store.getters.userFirstName,
          LastName: this.$store.getters.userLastName,
          Email: this.$store.getters.userEmail,
          Mobile: this.$store.getters.userMobile,
          CountryCode: this.$store.getters.destinationCountryCode,
        };
        if (!state.reservationSameAddress) {
          Contact.FirstName = state.reservationOther_ContactFirstName;
          Contact.LastName = state.reservationOther_ContactLastName;
          Contact.Email = state.reservationOther_ContactEmail;
          Contact.Mobile = state.reservationOther_ContactMobile;
        }
        const response = await axios.post("Reservation/Add", {
          ListingId: this.$route.params.id,
          SalesRepUserId: this.$store.state.reservation.reservationAgent.UserId,
          Contact,
        });
        // eslint-disable-next-line no-debugger
        if (payment.type === "credit") {
          this.creditPayment(payment.cardDetail, response);
        } else {
          await this.$store.dispatch("reservationNumber", response.data);
          await this.$store.dispatch("reservationSuccess", true);
          await this.$store.dispatch("reservation/reservationKeepData", false);
        }
        this.getSummaryFees()
        window.scrollTo(0,0);
      } catch (error) {
        this.$store.dispatch("showToast", {
          content: this.$t(error.response.data),
          type: "error",
        });
        if (process.env.NODE_ENV === "development") console.log(error.response.data);
        this.errorMessage = error.response.data;
        throw error;
      }
    },
    goToPage(page) {
      this.$refs["reservationWizard"].goTo(page);
    },
    getCountryByName(Name = "") {
      if (this.$store.getters.countries) {
        return _find(this.$store.getters.countries, { Name });
      }
      return {};
    },
    async getSummaryFees() {
      try {
        const response = await axios.post("Listing/DepositeAmount", {
          DeliveryCountryCode: this.$store.getters.destinationCountryCode
        });
        this.deposit = response.data.Amount;
      } catch (error) {
        if (process.env.NODE_ENV === "development") console.log(error.response.data);
        this.errorMessage = error.response.data;
      }
    },
    currencyFormatter(value) {
      return new Intl.NumberFormat(this.$store.getters.currencyCulture, {
        style: "currency",
        currency: this.$store.getters.currency,
        minimumFractionDigits: value % 1 != 0 ? 2 : 0,
      }).format(value);
    },
  },
  created() {
    this.$store.dispatch("clearListing");

    this.$store.dispatch("getListing", {
      id: this.$route.params.id,
    });
    this.getSummaryFees();
  },
  beforeMount() {
    //this.getArriveTime();
    //this.getExportPort();
    // this.getEntryPort();
    //this.getSummaryFees();
  },
  mounted() {
    if (this.$store.getters.reservationFailed) {
      this.goToPage(2);
      this.$store.dispatch("reservationFailed", false);
    }
  },
  beforeDestroy() {
    if (this.$route.name === "RegisterPage") return;
    if (!this.$store.getters.reservationKeepData)
      this.$store.dispatch("clearReservation");
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.dispatch("`reservation/reservationKeepData`", false);
      if (vm.$store.getters.listing) {
        if (vm.$store.getters.listing.IsReserved) {
          vm.$router.push({ name: "Home" });
        }
      }
    });
  },
};
</script>

<style scoped lang="scss">
.deliveryRoute {
  width: 390px;
  flex: none;
  margin-bottom: 30px;
  @media (max-width: 1199px) {
    width: 341px;
  }
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 8px;
  }
}

.left_col {
  max-width: 410px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 30px;
}
.roundedCorners {
  border-radius: 25px;
}

@media (max-width: 860px) {
  .left_col {
    padding-left: 0px;
    padding-right: 0px;
  }
  .left_col h3 {
    text-align: center;
  }
}
.reservation-header {
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    height: 64px;
  }
  .text-h4 {
    font-size: 24px !important;
  }
}
.delivery-route-card {
  margin: 24px 0;
  padding: 24px 24px 16px 24px;
  @media (max-width: 991px) {
    margin: 16px 0;
    padding: 16px;
  }
  .v-card__title {
    padding: 0;
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: 600;
  }
}
.country-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border: 1px solid #006ac3;
  border-radius: 5px;
  margin-bottom: 16px;
}
.guarantee-text {
  @media (max-width: 767px) {
    text-align: left;
  }
}
</style>
<style lang="scss">
.ReservationWizard {
  .v-expansion-panel-header:not(.v-expansion-panel-header--mousedown):focus::before {
    opacity: 0 !important;
  }
  .wizard__steps {
    display: none;
  }
  .wizard__body {
    padding: 0;
    background-color: transparent;
    margin: 0 !important;
    min-height: auto;
    .wizard__body__step {
      padding: 0;
    }
  }
  .wizard__body__actions {
    position: static;
    padding: 0;
    a {
      color: #fff !important;
    }
  }

  .country-selector__label {
    display: none;
  }
  .country-selector.has-hint .country-selector__input[data-v-46e105de],
  .country-selector.has-value .country-selector__input[data-v-46e105de] {
    padding-top: 0 !important;
  }
  .country-selector__country-flag[data-v-46e105de] {
    top: 14px;
    left: 17px;
  }
  .country-selector__toggle {
    display: none;
  }
  .input-group-append div {
    background-color: #fff;
  }
  .input-tel__input {
    border-left: none;
  }
  .select-country-container {
    width: 100px !important;
    min-width: unset !important;
    max-width: 100px !important;
    flex: 0 0 80px;
  }
  .v-radio .v-label {
    margin-bottom: 0 !important;
    margin-right: 12px !important;
    color: #000 !important;
  }
  .input-tel.input-phone-number input {
    box-shadow: none !important;
    outline: 0 !important;
    border-top: 1px solid #ccc !important;
    border-bottom: 1px solid #ccc !important;
  }
  .input-group-append div {
    border-color: #ccc !important;
  }
}
.reserve-success {
  &-text.text-h4 {
    font-size: 34px !important;
    font-weight: 600;
    margin-bottom: 16px;
    padding: 0 16px;
    text-align: center;
    @media (max-width: 991px) {
      font-size: 20px !important;
      line-height: 28px;
    }
  }
  &-model.text-h5 {
    font-size: 20px !important;
    line-height: 28px;
    margin-bottom: 16px;
    padding: 0 16px;
    text-align: center;
  }
  &-number.text-h5 {
    font-size: 16px !important;
    margin-bottom: 0;
    padding: 0 16px;
    text-align: center;
  }
}
.custom-alert {
  min-height: 75px;
  padding-top: 20px;
  text-align: center;
  &.danger-alert {
    background: #ffccb3;
  }
  &.success-alert {
    background: #dbf0d9;
  }
}
.important-info {
  padding: 24px;
  h4 {
    margin-bottom: 24px;
  }
  ul {
    padding: 0;
    list-style: none;
    margin-bottom: 0;
  }
  li {
    position: relative;
    padding-left: 22px;
    color: #363636;
    &:not(:last-child) {
      margin-bottom: 3px;
    }
    &:before {
      content: "";
      width: 8px;
      height: 8px;
      background: #4b4b4b;
      border-radius: 100%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      @media (max-width: 767px) {
        top: 13px;
        transform: translateY(0);
      }
    }
  }
}
.text-link {
  font-size: 16px !important;
  font-weight: 600;
  color: #006ac3 !important;
  letter-spacing: 0;
  text-transform: capitalize;
}
.action-buttons {
  margin-top: 24px;
  @media (max-width: 991px) {
    flex-direction: column;
  }
  .v-btn {
    min-width: 200px !important;
    height: 48px !important;
    font-size: 16px !important;
    letter-spacing: 0;
    text-transform: capitalize;
    font-weight: 600;
    @media (max-width: 991px) {
      min-width: 100% !important;
    }
  }
  .find-btn {
    margin-left: 40px;
    @media (max-width: 991px) {
      margin-left: 0 !important;
      margin-top: 16px;
    }
  }
}
.agent-card {
  width: 780px;
  @media (max-width: 991px) {
    width: 100%;
  }
}
</style>
